/**
 * Checks to see if a component is a component master or instance.
 * If it's a component master, it will be found in the `Components` object on the project.
 *
 * Note: Projects without a custom component master will not have a `Components` object at all.
 *
 * @param project - the active project
 * @param componentName - the name of the component to be checked
 * @returns
 */
export function isComponentMaster(project, componentName) {
    return !!project.Components?.[componentName];
}
/**
 * Checks for null or undefined, lifted from https://ramdajs.com/docs/#isNil
 */
export const isNil = (x) => x == null;
export function noop() {
    // do nothing
}
/*
These are the rules for choosing names:

- the default name for a thing is "thing" (no suffix)
- if "thing" is taken
  - if there are no number-suffixed "thing"s the name will be "thing 2"
  - if there are one or more number-suffixed "thing"s the name will be "thing" suffixed with
    a number one more than the highest suffix
- when number suffixes are added they are preceded or not by a space to match the scheme in use

So names proceed like: "thing", "thing 2", "thing 3". If "thing" is deleted the next name will be
"thing 4". Then if "thing 4" is deleted the next name will be "thing 4".
*/
export function suggestName(baseName, takenNames, alwaysSuffix = false) {
    const unsuffixed = trimNumberSuffix(baseName);
    let space = true;
    let highestSuffix = 0;
    for (const takenName of takenNames) {
        const name = trimNumberSuffix(takenName);
        if (name === unsuffixed) {
            const suffix = takenName.slice(unsuffixed.length);
            if (suffix) {
                const n = parseInt(suffix);
                if (n > highestSuffix) {
                    highestSuffix = n;
                    space = suffix[0] === ' ';
                }
            }
        }
    }
    if (!alwaysSuffix && highestSuffix === 0 && !takenNames.includes(unsuffixed)) {
        return unsuffixed;
    }
    return unsuffixed + (space ? ' ' : '') + Math.max(2, highestSuffix + 1);
}
// TODO: Find an NPM module for this.
export function hexpad(s) {
    if (typeof s === 'number') {
        s = s.toString();
    }
    return parseInt(s).toString(16).padStart(2, '0');
}
export function encode64(buffer) {
    return btoa(new Uint8Array(buffer).reduce((s, b) => s + String.fromCharCode(b), ''));
}
export function hex(buffer) {
    return [].map
        .call(new Uint8Array(buffer), (b) => ('00' + b.toString(16)).slice(-2))
        .join('');
}
/**
 *
 * A tiny util that allows the usage of async/await without a try/catch.
 * It returns an array where the first entry is any error thrown, and the
 * second is the res data.
 *
 * Usage: const [err, data] = await fromPromise(myAsyncFn())
 */
export async function fromPromise(promise) {
    try {
        const data = await promise;
        return [null, data];
    }
    catch (e) {
        return [e];
    }
}
export async function fromPromiseRetry(promise, maxRetries = 6, onRetry) {
    async function retryWithBackoff(retries) {
        try {
            if (retries > 0) {
                const timeToWait = 2 ** retries * 100;
                await sleep(timeToWait);
            }
            const data = await promise;
            return [null, data];
        }
        catch (e) {
            // only retry if we didn't reach the limit
            // otherwise, let the caller handle the error
            if (retries < maxRetries) {
                if (onRetry)
                    onRetry(retries);
                return retryWithBackoff(retries + 1);
            }
            else {
                console.warn('Max retries reached. Bubbling the error up');
                return [e];
            }
        }
    }
    return retryWithBackoff(0);
}
export function upperFirstLetter(s) {
    return s[0].toUpperCase() + s.slice(1);
}
export function lowerFirstLetter(s) {
    return s[0].toLowerCase() + s.slice(1);
}
// button123 -> button, button 2 -> button, b1tton -> b1tton, 13434bb -> 13434bb,
export function trimNumberSuffix(s) {
    return s.replace(/^(.*?)(\s*[0-9]*)$/, '$1');
}
export function isDescendant(el, parentId) {
    let isChild = false;
    if (el.id === parentId) {
        //is this the element itself?
        isChild = true;
    }
    while ((el = el.parentNode)) {
        if (el.id == parentId) {
            isChild = true;
        }
    }
    return isChild;
}
export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
const sep = '/';
const dupeSep = new RegExp(sep + '{1,}', 'g');
// a reasonable client side join a la node. does not take into account all the various edge
// cases of a protocol-compliant join, but should be good enough for our purposes.
export function joinPath(pathParts) {
    const [maybeProtocol, ...rest] = pathParts;
    // try just a little
    const isRelative = maybeProtocol.startsWith(sep) || maybeProtocol.startsWith('.');
    const dedupe = isRelative ? pathParts : rest;
    // we don't want to accidentally remove something like `//` from a protocol
    const noProtocol = dedupe.join(sep).replace(dupeSep, sep);
    return isRelative ? noProtocol : maybeProtocol + noProtocol;
}
export function buildPath(basePath, params) {
    const encodedBase = encodeURI(basePath);
    if (!params)
        return encodedBase;
    const paramEntries = Object.entries(params).map(([key, value]) => value ? `${key}=${encodeURIComponent(value)}` : key);
    if (!paramEntries.length)
        return encodedBase;
    return `${encodedBase}?${paramEntries.join('&')}`;
}
export function buildUrl(url, base, params) {
    const urlObj = new URL(url, base);
    return buildPath(decodeURI(urlObj.toString()), params);
}
/**
 * Modulate a value between two ranges.
 *
 * @example
 *
 * ```ts
 * const A = modulate(0, [0, 1], [0, 100])
 * ```
 *
 * @param value - The interpolation value.
 * @param rangeA - From [low, high]
 * @param rangeB - To [low, high]
 * @param clamp - Whether to clamp the the result to [low, high]
 * @public
 */
export function modulate(value, rangeA, rangeB, clamp = false) {
    const [fromLow, fromHigh] = rangeA;
    const [v0, v1] = rangeB;
    const result = v0 + ((value - fromLow) / (fromHigh - fromLow)) * (v1 - v0);
    return clamp
        ? v0 < v1
            ? Math.max(Math.min(result, v1), v0)
            : Math.max(Math.min(result, v0), v1)
        : result;
}
export function toPrecision(n, precision = 10000000000) {
    if (!n)
        return 0;
    return Math.round(n * precision) / precision;
}
