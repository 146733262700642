import { extendDeep, makeImmutable } from '@playful/utils';
import { DESCRIPTION } from '../constants.js';
import { appendPrototype } from '../reactorObject.js';
import { getDefaultMetaData } from './metadata.js';
import { ComponentBase } from './component.js';
// Declare an effect
export function declareEffect(name, prototype, meta) {
    // Effects inherit from ComponentBase
    appendPrototype(prototype, ComponentBase);
    const baseMeta = {
        isEffect: true,
        title: name,
        author: 'Hatch',
        properties: {},
    };
    return {
        built: true, // Don't need to run buildComponentDescription
        name,
        prototype,
        _meta: makeImmutable(extendDeep({}, meta, baseMeta, getDefaultMetaData())),
    };
}
export function isEffect(component) {
    return component[DESCRIPTION]?._meta?.isEffect === true;
}
