import { defineStyleConfig } from '@chakra-ui/react';
export const popoverTheme = defineStyleConfig({
    defaultProps: {},
    baseStyle: {
        popper: { zIndex: 1800 },
        content: {
            '--popper-bg': 'colors.white',
            bg: 'var(--popper-bg)',
            '--popper-arrow-bg': 'var(--popper-bg)',
            '--popper-arrow-shadow-color': 'colors.gray.100',
            width: 'xs',
            border: '0.0625rem solid',
            borderColor: 'gray.100',
            borderRadius: 'md',
            boxShadow: 'md',
            zIndex: 'inherit',
        },
        header: { px: 6, py: 4, borderBottom: '0.0625rem solid', borderBottomColor: 'gray.100' },
        body: { px: 6, py: 4 },
        footer: { px: 6, py: 4, borderTop: '0.0625rem solid', borderTopColor: 'gray.100' },
        arrow: {},
        closeButton: {
            position: 'absolute',
            borderRadius: 'full',
            top: 1,
            insetEnd: 2,
            padding: 2,
            _focus: { boxShadow: 'none' },
            _focusVisible: {
                outline: '0.125rem solid',
                outlineColor: 'gray.800',
                outlineOffset: '0.125rem',
            },
        },
    },
    variants: {
        tooltip: {
            content: {
                color: 'whiteAlpha.900',
                paddingX: 2,
                paddingY: 0.5,
                border: 'none',
                '--popper-bg': 'gray.700',
                bg: 'var(--popper-bg)',
                '--popper-arrow-bg': 'var(--popper-bg)',
                '--popper-arrow-shadow-color': 'colors.gray.700',
            },
        },
    },
    sizes: {},
});
