import { isReactor } from '../reactor.js';
import { ACTIVE_BREAKPOINT, VARIANT_ID } from '../constants.js';
export const PRIMARY_BREAKPOINT_ID = 'desktop';
export const DEFAULT_BREAKPOINTS = {
    // Note: desktop is the PRIMARY breakpoint so you won't encounter this ID.
    // The PRIMARY breakpoint is used when the breakpointId is undefined.
    [PRIMARY_BREAKPOINT_ID]: {
        variantId: PRIMARY_BREAKPOINT_ID,
        name: 'Desktop',
        width: 1200,
    },
    tablet: {
        variantId: 'tablet',
        name: 'Tablet',
        width: 768,
    },
    mobile: {
        variantId: 'mobile',
        name: 'Mobile',
        width: 375,
    },
};
export function isVariantReactor(reactor) {
    return isReactor(reactor) && reactor[VARIANT_ID];
}
export function isPropertyOverridden(component, property) {
    const activeBreakpointReactor = component[ACTIVE_BREAKPOINT];
    if (!activeBreakpointReactor) {
        return false;
    }
    return property in activeBreakpointReactor;
}
