import { loadProject, migrateProject } from './project.js';
import { deserialize } from './reactor.js';
import { dirName, pathJoin } from './util.js';
function getImportProtocolAndPath(importInfo) {
    if (!importInfo.source) {
        return ['', ''];
    }
    const s = importInfo.source.split(':');
    return [s[0], s.slice(1).join(':')];
}
export async function importProjectOrModule(importInfo, project, playkit) {
    const imports = project.imports;
    // This allows unit tests to 'shim' the import
    if (importInfo.loader) {
        return importInfo
            .loader()
            .then((module) => {
            imports[importInfo.name] = module;
        })
            .catch((err) => console.error(err));
    }
    const [protocol, path] = getImportProtocolAndPath(importInfo);
    if (!protocol) {
        return;
    }
    //console.log(`import ${importInfo.name} (${importInfo.source})`);
    switch (protocol) {
        // Handle playkit imports
        case 'playkit': {
            if (!playkit) {
                console.error(`playkit import requested but no playkit module provided`);
            }
            imports[importInfo.name] = playkit;
            return;
        }
        // Import components from subproject within this project's resource
        case 'project': {
            // resourceRoot is the root for importing the sub project
            const resourceRoot = pathJoin([project.resourceRoot, dirName(path)]);
            // Create a url for the sub project
            let url;
            if (importInfo.sourceHash) {
                url = pathJoin([project.mainProject.resourceRoot, `sha256:${importInfo.sourceHash}`]);
            }
            else {
                url = pathJoin([project.resourceRoot, path]);
            }
            return readRemoteProject(url)
                .then(({ state }) => importProjectState(state, importInfo, project, resourceRoot, playkit))
                .catch((err) => {
                console.error(err);
            });
        }
        default:
            console.error(`unknown import protocol: ${importInfo.source}`);
    }
}
function importProjectState(state, importInfo, project, resourceRoot, playkit) {
    const imports = project.imports;
    return loadProject({
        state,
        info: { title: importInfo.name },
        mainProject: project.mainProject,
        resourceRoot,
        playkit,
    }).then((importedProject) => {
        imports[importInfo.name] = importedProject;
    });
}
export function unimportProjectOrModule(imp, project) {
    delete project.imports[imp.name];
}
export function importProjectsAndModules(project, playkit) {
    const Imports = project.Imports;
    if (!Imports) {
        return Promise.resolve();
    }
    return Promise.allSettled(Imports.map((info) => importProjectOrModule(info, project, playkit)));
}
export async function readRemoteProject(url) {
    const response = await fetch(url);
    const jsonText = await response.text();
    const json = deserialize(jsonText);
    // Upgrade the project to the latest format.
    const { newMigrations } = await migrateProject(json);
    return { state: json, headers: response.headers, newMigrations };
}
