/**
 * Define the types to support Declarative Inspectors.
 *
 * Declarative Inspectors begin as a Description on Components.
 * The Description is parsed by the Workbench and turned into a "concrete" Inspector.
 * The concrete Inspector is rendered by the Workbench, and then used to mutate the Component.
 *
 */
// Tokens can be imported to a designRuntime environment, like PlayKit
// When used in an Inspector Description, they will be replaced with a concrete implementation
// when the Description is parsed by the Frontend & Workbench
export const SeparatorToken = '__separator__';
export const VerticalSeparatorToken = '__vertical_separator__';
export const AccordionToken = '__accordion__';
export const GroupToken = '__group__';
export const StylesAccordionToken = '__styles_accordion__';
export const ToolbarToken = '__toolbar__';
export const ToolbarButtonToken = '__toolbar_button__';
export const ToolbarMenuButtonToken = '__toolbar_menu_button__';
export const ToolbarInfoButtonToken = '__toolbar_info_button__';
export const FilteredGroupToken = '__filtered__';
export const GridToken = '__grid__';
export const RowToken = '__row__';
export const FlexGridToken = '__flex_grid__';
export const AdditionalOptionsModalToken = '__additional_options_modal__';
export const DeletePropertiesToken = '__delete_properties__';
export const PropertiesToggleToken = '__properties_toggle__';
// Spread tokens are special. Instead of being turned into a React Component
// in the frontend, the DeclarativeInspector "dumps" all properties or commands
// that have not been inspected elsewhere.
// For example, lets say you always wanted the Name to be the first property.
// but you didn't care much about the rest, your Description could be:
// ['name', SpreadRestPropertiesToken]
// The name will be first, then all the other properties will be "spread" next.
export const SpreadRestPropertiesToken = '__rest__';
export const SpreadRestCommandsToken = '__rest_commands__';
export const ComponentInspector = '__component_inspector__';
export const QuickInspector = '__quick_inspector__';
export const AdvancedInspector = '__advanced_inspector__';
export const PositionInspector = '__positions_inspector__';
export const StylesInspector = '__style_inspector__';
export const PromptInspectorToken = '__prompt_inspector__';
export const DrawingToken = '__drawing_inspector__';
export const AddChildToken = '__add_child_inspector__';
export const DeleteAction = '__delete_action__';
export const DuplicateAction = '__duplicate_action__';
export const OrderAction = '__order_action__';
export const MoreAction = '__more_action__';
export const EffectsAction = '__effects_action__';
export const ActionsAction = '__actions_action__';
export const LinkAction = '__link_action__';
export const UploadAction = '__upload_action__';
export const AttributionAction = '__attribution_action__';
export const HelpAction = '__help_action__';
export const EditTextIconToken = '__edit_text_icon__';
export const PositionIconToken = '__position_icon__';
export const DeleteIconToken = '__delete_icon__';
export const StyleIconToken = '__style_icon__';
export const EffectsIconToken = '__effects_icon__';
export const ActionsIconToken = '__actions_icon__';
export const DuplicateIconToken = '__duplicate_icon__';
export const AdvancedIconToken = '__advanced_icon__';
export const PhysicsIconToken = '__physics_icon__';
export const OrderIconToken = '__order_icon__';
export const MoreIconToken = '__more_icon__';
export const LeftTextAlignIconToken = '__left_text_align__';
export const CenterTextAlignIconToken = '__center_text_align__';
export const RightTextAlignIconToken = '__right_text_align__';
export const BottomTextAlignIconToken = '__bottom_text_align__';
export const VerticalCenterTextAlignIconToken = '__vertical_center_text_align__';
export const TopTextAlignIconToken = '__top_text_align__';
export const LeftAlignIconToken = '__left_object_align__';
export const CenterAlignIconToken = '__center_object_align__';
export const RightAlignIconToken = '__right_object_align__';
export const BottomAlignIconToken = '__bottom_object_align__';
export const MiddleAlignIconToken = '__middle_object_align__';
export const TopAlignIconToken = '__top_object_align__';
export const ImageIconToken = '__image_icon__';
export const LinkIconToken = '__link_icon__';
export const UploadIconToken = '__upload_icon__';
export const HTMLIconToken = '__html_icon__';
export const GearIconToken = '__gear_icon__';
export const PropertiesChangeIconToken = '__properties_change_icon__';
export const InfoCircleIconToken = '__info_circle_icon__';
export const QuestionCircleIconToken = '__question_circle_icon__';
export const InViewAnyIconToken = '__inview_any_icon__';
export const InViewHalfIconToken = '__inview_half_icon__';
export const InViewAllIconToken = '__inview_all_icon__';
export const ArrowHorizontalIconToken = '__arrow_horizontal_icon__';
export const ArrowVerticalIconToken = '__arrow_vertical_icon__';
export const ArrowLeftIconToken = '__arrow_left_icon__';
export const ArrowRightIconToken = '__arrow_right_icon__';
export const ArrowUpIconToken = '__arrow_up_icon__';
export const ArrowDownIconToken = '__arrow_down_icon__';
export const ArrowMiddleHorizontalIconToken = '__arrow_middle_horizontal_icon__';
export const ArrowMiddleVerticalIconToken = '__arrow_middle_vertical_icon__';
export const VDistributionTopIconToken = '__v_distribution_top_icon__';
export const VDistributionCenterIconToken = '__v_distribution_center_icon__';
export const VDistributionBottomIconToken = '__v_distribution_bottom_icon__';
export const HDistributionLeftIconToken = '__h_distribution_left_icon__';
export const HDistributionCenterIconToken = '__h_distribution_center_icon__';
export const HDistributionRightIconToken = '__h_distribution_right_icon__';
export const VDistributionSpaceBetweenIconToken = '__v_distribution_space_between_icon__';
export const HDistributionSpaceBetweenIconToken = '__h_distribution_space_between_icon__';
export const VDistributionSpaceAroundIconToken = '__v_distribution_space_around_icon__';
export const HDistributionSpaceAroundIconToken = '__h_distribution_space_around_icon__';
export const VDistributionSpaceEvenlyIconToken = '__v_distribution_space_evenly_icon__';
export const HDistributionSpaceEvenlyIconToken = '__h_distribution_space_evenly_icon__';
export const FixedWidthIconToken = '__fixed_width_icon__';
export const FixedHeightIconToken = '__fixed_height_icon__';
export const PageIconToken = '__page_icon__';
export const FixedSizeTextIconToken = '__fixed_size_text_icon__';
export const FixedWidthTextIconToken = '__fixed_width_text_icon__';
export const DynamicSizeTextIconToken = '__dynamic_size_text_icon__';
export const InViewIconToken = '__inview_icon__';
export const ScrollingIconToken = '__scrolling_icon__';
export const PageLoadIconToken = '__page_load_icon__';
export const HoverIconToken = '__hover_icon__';
export const ClickIconToken = '__click_icon__';
export const OverlapIconToken = '__overlap_icon__';
export const PressReleaseIconToken = '__press_release_icon__';
export const PointerDistanceIconToken = '__pointer_distance_icon__';
export function isInspectorGroupDescription(inspector) {
    return !!inspector && typeof inspector === 'object' && 'inspectors' in inspector;
}
export function isInspectorActionDescription(inspector) {
    return (!!inspector &&
        typeof inspector === 'object' &&
        !('inspectors' in inspector) &&
        'title' in inspector);
}
export function isInspectorSingleDescription(inspector) {
    return !!inspector && typeof inspector === 'object' && 'name' in inspector;
}
export function isSpreadToken(token) {
    return token === SpreadRestCommandsToken || token === SpreadRestPropertiesToken;
}
